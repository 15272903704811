<template>
  <div>
    <div v-if="tabs_mode">
      <b-tabs content-class="mt-3">
        <b-tab
          :title="
            $getVisibleNames(
              'manual.criterio_de_evaluacion',
              false,
              'Criterio de Evaluación'
            )
          "
          active
        >
          <!-- <b-form-group
            label="Indicador de Desempeño"
            label-cols="0"
            label-cols-lg="3"
            label-for="select-performance-indicator"
            description="De las Competencias Genéricas."
            class="my-0"
          >
            <b-form-select
              v-model="$v.evaluation_criteria.performance_indicator.$model"
              id="select-performance-indicator"
              :state="validateState('performance_indicator')"
              aria-describedby="select-performance-indicator-feedback"
              size="sm"
            >
              <template v-for="competence in institutionCompetences">
                <b-form-select-option-group
                  :key="competence.id"
                  :label="competence.name"
                  v-if="performanceIndicatorOptions(competence).length > 0"
                >
                  <b-form-select-option
                    v-for="indicator in performanceIndicatorOptions(competence)"
                    :value="indicator.id"
                    :key="indicator.id"
                  >
                    {{ indicator.sentence }}
                  </b-form-select-option>
                </b-form-select-option-group>
              </template>
              <b-form-select-option
                :value="null"
                label="No Aplica Indicador de Desempeño"
                style="font-weight: bold"
              >
              </b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback id="select-performance-indicator-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group> -->
          <SentenceForm
            :first_content="evaluation_criteria.action"
            :second_content="evaluation_criteria.content"
            :third_content="evaluation_criteria.condition"
            :full_sentence="evaluation_criteria.full_sentence"
            :verb="evaluation_criteria.verb"
            third_content_comment
            @update="slotUpdateSentence"
            :allows_crud="
              evaluation_criteria.performance_indicator ? false : true
            "
          ></SentenceForm>
          <b-form-group
            v-if="
              this.studyUnit && !this.studyUnit.automatic_achievement_weighing
            "
            label="Ponderación:"
            label-cols="0"
            label-cols-lg="3"
            label-for="input-weighing"
            :description="`Ponderación con respecto al Resultado de Aprendizaje. Disponible ${max_weighing}%.`"
            class="m-0 mb-3"
          >
            <b-form-input
              id="input-weighing"
              type="number"
              min="0"
              v-model="$v.evaluation_criteria.weighing.$model"
              :state="validateState('weighing')"
              aria-describedby="input-weighing-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-weighing-feedback"
              >Este campo es obligatorio y no debe exceder
              {{ max_weighing }}.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-tab>
        <!-- <b-tab
          title="Detalles"
        >
          <b-form-group
            :label="$getVisibleNames('teaching.ramicro', false, 'RA Micro')"
            label-cols="0"
            label-cols-lg="3"
            class="my-0"
          >
            {{ studyUnit ? studyUnit.name : "" }}
          </b-form-group>
          <b-form-group
            label="Resultado de Aprendizaje"
            label-cols="0"
            label-cols-lg="3"
            class="my-0"
          >
            <span v-if="studyUnit">
              {{
                studyUnit.action && studyUnit.content && studyUnit.condition
                  ? studyUnit.action +
                    " " +
                    studyUnit.content +
                    " " +
                    studyUnit.condition
                  : "No Aplica."
              }}
            </span>
          </b-form-group>
          <b-form-group
            label="Ponderación"
            label-cols="0"
            label-cols-lg="3"
            label-for="input-weighing"
            :description="`Ponderación con respecto al Resultado de Aprendizaje. Disponible ${max_weighing}%.`"
            class="my-0"
          >
            <b-form-input
              id="input-weighing"
              type="number"
              min="0"
              v-model="$v.evaluation_criteria.weighing.$model"
              :state="validateState('weighing')"
              aria-describedby="input-weighing-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-weighing-feedback"
              >Este campo es obligatorio y no debe exceder
              {{ max_weighing }}.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            label="Orden"
            label-cols="0"
            label-cols-lg="3"
            label-for="input-order"
            :description="`Orden en que se mostrará el ${$getVisibleNames(
              'manual.criterio_de_evaluacion',
              false,
              'Criterio de Evaluación'
            )}.`"
            class="my-0"
          >
            <b-form-input
              id="input-order"
              type="number"
              min="1"
              v-model="$v.evaluation_criteria.order.$model"
              :state="validateState('order')"
              aria-describedby="input-order-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-order-feedback"
              >Este campo es obligatorio y no debe ser menor a
              1.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-tab> -->
      </b-tabs>
    </div>
    <div v-else>
      <h4>
        <span v-if="isNaN(evaluation_criteria.id)">Crear</span>
        <span v-else>Modificar</span>
        {{
          $getVisibleNames(
            "manual.criterio_de_evaluacion",
            false,
            "Criterio de Evaluación"
          )
        }}
        <!-- Criterio de Evaluación -->
        <div class="mx-2"></div>
        <div>
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            label-size="sm"
            label="Ponderación"
            label-for="input-weighing"
          >
            <b-form-input
              type="number"
              id="input-weighing"
              v-model="$v.evaluation_criteria.weighing.$model"
              :state="validateState('weighing')"
              aria-describedby="input-weighing-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-weighing-feedback"
              >Este campo es obligatorio y debe estar entre 0 y
              {{ max_weighing }}.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
      </h4>
      <div>
        <SentenceForm
          :first_content="evaluation_criteria.action"
          :second_content="evaluation_criteria.content"
          :third_content="evaluation_criteria.condition"
          :full_sentence="evaluation_criteria.full_sentence"
          :verb="evaluation_criteria.verb"
          third_content_comment
          @update="slotUpdateSentence"
          :allows_crud="
            evaluation_criteria.performance_indicator ? false : true
          "
        ></SentenceForm>
      </div>
    </div>
    <b-form-group
      v-if="!isNaN(this.evaluation_criteria.id)"
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2"
    >
      <ModificationDate :Information="evaluation_criteria"></ModificationDate>
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(evaluation_criteria.id)"
        class="col"
        style="text-align: left"
      >
        <b-button class="mr-1" size="sm" variant="danger" @click="remove"
          >Eliminar
          {{
            $getVisibleNames(
              "manual.criterio_de_evaluacion",
              false,
              "Criterio de Evaluación"
            )
          }}</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save"
          >Guardar
          {{
            $getVisibleNames(
              "manual.criterio_de_evaluacion",
              false,
              "Criterio de Evaluación"
            )
          }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minValue, maxValue } from "vuelidate/lib/validators";

export default {
  name: "EvaluationCriteriaForm",
  mixins: [validationMixin],
  components: {
    SentenceForm: () => import("@/components/reusable/SentenceForm"),
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    study_unit_id: {
      type: Number,
      required: true,
    },
    // profile_matter_id: {
    //   type: Number,
    //   required: true,
    // },
    EvaluationCriteria: {
      type: Object,
    },
    max_weighing: {
      type: Number,
      default: 100,
    },
    order: {
      type: Number,
      default: 1,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    tabs_mode: {
      type: Boolean,
      default: true,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      evaluation_criteria: {
        id: this.EvaluationCriteria
          ? this.EvaluationCriteria.id
          : generateUniqueId(),
        order: this.EvaluationCriteria
          ? this.EvaluationCriteria.order
          : this.order,
        action: this.EvaluationCriteria ? this.EvaluationCriteria.action : "",
        content: this.EvaluationCriteria ? this.EvaluationCriteria.content : "",
        condition: this.EvaluationCriteria
          ? this.EvaluationCriteria.condition
          : "",

        full_sentence: this.EvaluationCriteria
          ? this.EvaluationCriteria.full_sentence
          : null,
        study_unit: this.EvaluationCriteria
          ? this.EvaluationCriteria.study_unit
          : this.study_unit_id,
        weighing: this.EvaluationCriteria
          ? this.EvaluationCriteria.weighing
          : 0,
        competence: this.EvaluationCriteria
          ? this.EvaluationCriteria.competence
          : null,
        performance_indicator: this.EvaluationCriteria
          ? this.EvaluationCriteria.performance_indicator
          : null,
        verb: this.EvaluationCriteria ? this.EvaluationCriteria.verb : null,
        updated_by: this.EvaluationCriteria
          ? this.EvaluationCriteria.updated_by
          : null,
        update_date: this.EvaluationCriteria
          ? this.EvaluationCriteria.update_date
          : null,
      },
      base_sentence_toggle: this.EvaluationCriteria
        ? this.EvaluationCriteria.content
          ? true
          : false
        : true,
    };
  },
  validations() {
    return {
      evaluation_criteria: {
        order: {
          required,
          minValue: minValue(1),
        },
        action: {
          isValid() {
            return this.isValidSentence();
          },
        },
        content: {
          isValid() {
            return this.isValidSentence();
          },
        },
        condition: {
          isValid() {
            return this.isValidSentence();
          },
        },
        weighing: {
          required,
          maxVlaue: maxValue(this.max_weighing),
        },
        full_sentence: {
          isValid() {
            return this.isValidSentence();
          },
        },
        competence: {},
        performance_indicator: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      studyUnits: names.STUDY_UNITS,
      matters: names.MATTERS,
      // competences: names.COMPETENCES,
      performanceIndicators: names.PERFORMANCE_INDICATORS,
      // institutionCompetences: names.INSTITUTION_COMPETENCES,
    }),
    studyUnit() {
      return this.studyUnits.find(
        (x) => x.id == this.evaluation_criteria.study_unit
      );
    },
    matter() {
      if (!this.studyUnit) return null;
      return this.matters.find((x) => x.id == this.studyUnit.matter);
    },
    filteredPerformanceIndicators() {
      let list = [];
      if (!this.studyUnit) return list;
      this.studyUnit.performance_indicators.forEach((element) => {
        const instance = this.performanceIndicators.find(
          (x) => x.id == element
        );
        if (instance) list.push(instance);
        else console.log("No está el Indicador...");
      });
      return list;
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.evaluation_criteria[key];
      return $dirty ? !$error : null;
    },
    slotUpdateSentence(sentence) {
      this.evaluation_criteria.verb = sentence.verb;
      this.evaluation_criteria.action = sentence.first_content;
      this.evaluation_criteria.content = sentence.second_content;
      this.evaluation_criteria.condition = sentence.third_content;
      this.evaluation_criteria.full_sentence = sentence.full_sentence;
      this.base_sentence_toggle = sentence.base_sentence_toggle;
      this.$emit("changed", this.evaluation_criteria);
    },
    // performanceIndicatorOptions(competence) {
    //   let list = [];
    //   this.studyUnit.performance_indicators.forEach((element) => {
    //     const instance = this.performanceIndicators.find(
    //       (x) => x.id == element && x.institution_competence == competence.id
    //     );
    //     if (instance) list.push(instance);
    //   });
    //   return list;
    // },
    save() {
      // if (this.disciplinar_competence && this.professional_competence) {
      //   this.$swal({
      //     title: String(
      //       "Seleccione solo un " +
      //         this.$getVisibleNames(
      //           "mesh.competencetype",
      //           false,
      //           "Tipo De Competencia"
      //         ) +
      //         ": Disciplinar o Profesional antes de guardar"
      //     ),
      //     type: "error",
      //   });
      // } else {
      this.fillEvaluationCriteria();
      this.$v.evaluation_criteria.$touch();
      if (this.$v.evaluation_criteria.$anyError) {
        return;
      }
      if (this.base_sentence_toggle) {
        this.evaluation_criteria.full_sentence = String(
          this.evaluation_criteria.action +
            " " +
            this.evaluation_criteria.content +
            " " +
            this.evaluation_criteria.condition
        );
        if (isNaN(this.evaluation_criteria.id)) this.createEvaluationCriteria();
        else this.updateEvaluationCriteria();
      } else {
        if (
          this.evaluation_criteria.full_sentence ||
          !this.evaluation_criteria.full_sentence.trim() == ""
        ) {
          if (isNaN(this.evaluation_criteria.id))
            this.createEvaluationCriteria();
          else this.updateEvaluationCriteria();
        }
      }
      // }
    },
    isValidSentence() {
      if (this.base_sentence_toggle) {
        if (
          this.evaluation_criteria.action.trim() != "" &&
          this.evaluation_criteria.content.trim() != "" &&
          this.evaluation_criteria.condition.trim() != ""
        )
          return true;
        else return false;
      } else if (this.evaluation_criteria.full_sentence.trim() != "")
        return true;
      else return false;
    },
    remove() {
      if (!this.allows_crud && !isNaN(this.evaluation_criteria.id)) {
        this.$emit("delete", this.evaluation_criteria);
        return;
      }
      this.$$store.dispatch(
        names.DELETE_EVALUATION_CRITERIA,
        this.evaluation_criteria.id
      );
      this.$emit("deleted", this.evaluation_criteria);
    },
    createEvaluationCriteria() {
      if (!this.allows_crud || isNaN(this.study_unit_id)) {
        this.$emit("create", this.evaluation_criteria);
        return;
      }
      this.$store
        .dispatch(names.POST_EVALUATION_CRITERIA, this.evaluation_criteria)
        .then(() => {
          if (this.studyUnit && this.studyUnit.automatic_achievement_weighing)
            this.$restful
              .Get(
                `teaching/update_evaluation_criteria_micro_weighing/?study_unit=${this.study_unit_id}`
              )
              .then(() => {
                this.$store.dispatch(names.FETCH_EVALUATION_CRITERIAS, {
                  study_unit_id: this.study_unit_id,
                });
              });
        });
      this.$emit("created", this.evaluation_criteria);
    },
    updateEvaluationCriteria() {
      if (!this.allows_crud) {
        this.$emit("update", this.evaluation_criteria);
        return;
      }
      this.$store.dispatch(
        names.UPDATE_EVALUATION_CRITERIA,
        this.evaluation_criteria
      );
      this.$emit("updated", this.evaluation_criteria);
    },
    fillEvaluationCriteria() {
      if (
        this.evaluation_criteria.action == "" &&
        this.evaluation_criteria.content == "" &&
        this.evaluation_criteria.condition == ""
      ) {
        if (this.$v.evaluation_criteria.performance_indicator.$model) {
          let default_evaluation_criteria =
            this.filteredPerformanceIndicators.find(
              (x) =>
                x.id == this.$v.evaluation_criteria.performance_indicator.$model
            );
          this.evaluation_criteria.action = default_evaluation_criteria.action;
          this.evaluation_criteria.content =
            default_evaluation_criteria.content;
          this.evaluation_criteria.condition =
            default_evaluation_criteria.condition;
        }
      }
    },
  },
  watch: {
    "evaluation_criteria.performance_indicator"() {
      if (this.evaluation_criteria.performance_indicator) {
        const performance_indicator = this.performanceIndicators.find(
          (x) => x.id == this.evaluation_criteria.performance_indicator
        );
        if (!performance_indicator) return;
        this.evaluation_criteria.action = performance_indicator.action;
        this.evaluation_criteria.content = performance_indicator.content;
        this.evaluation_criteria.condition = performance_indicator.condition;
      }
    },
  },
  created() {
    this.$store
      .dispatch(names.FETCH_STUDY_UNIT, this.study_unit_id)
      .then((study_unit) => {
        this.$store.dispatch(names.FETCH_MATTER, study_unit.matter);
        // .then((matter) => {
        //   matter.competences.forEach((competence_id) => {
        //     this.$store.dispatch(names.FETCH_COMPETENCE, competence_id);
        //   });
        // });
      });
    // this.$store
    //   .dispatch(names.FETCH_EGRESS_PROFILE_MATTER, this.profile_matter_id)
    //   .then((profile_matter) => {
    //     profile_matter.competences.forEach((competence_id) => {
    //       this.$store.dispatch(names.FETCH_COMPETENCE, competence_id);
    //     });
    //   });
    // if (this.evaluation_criteria.competence) {
    //   if (
    //     this.competences.find(
    //       (x) => x.id == this.evaluation_criteria.competence
    //     ).type == 1
    //   ) {
    //     this.disciplinar_competence = this.evaluation_criteria.competence;
    //   }
    //   if (
    //     this.competences.find(
    //       (x) => x.id == this.evaluation_criteria.competence
    //     ).type == 2
    //   ) {
    //     this.professional_competence = this.evaluation_criteria.competence;
    //   }
    // }
  },
};
</script>

<style scoped>
h4,
.h4 {
  text-align: center;
}
</style>